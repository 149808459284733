import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "add-User", fluid: "", tag: "section" } },
        [
          _c(
            "div",
            [
              _c("ManageUserBasic", {
                attrs: {
                  UserToAddType: _vm.UserTypeEnum.Partner,
                  user_prop: _vm.user,
                  UserToAddInfo: _vm.partnerInfo,
                },
                on: { alert: _vm.showAlert, saved: _vm.userSaved },
              }),
              _vm.alertMsg != null
                ? _c(
                    VAlert,
                    {
                      staticClass: "ma-2",
                      attrs: { type: _vm.success ? "success" : "error" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.alertMsg)) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }